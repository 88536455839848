import * as React from 'react';
import styled from '@emotion/styled';
import { useAppContext } from '../../contexts/AppContext';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from '../Link';
import Image, { ImageLoaderProps } from 'next/image';
import { WithNavigationbar } from '../NavigationBar/NavigationBar';

const ButtonLogo = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down(456)]: {
    height: '32px',
    minWidth: '40%',
  },
  [theme.breakpoints.down(767)]: {
    height: '48px',
    minWidth: '40%',
  },
  [theme.breakpoints.up(767)]: {
    width: theme.theme_component_navigation_bar_logo_width || '15%',
    height: '60px',
    minWidth: '200px',
  },
})) as typeof Button;

const ToolbarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down(456)]: {
    height: '40px',
  },
  [theme.breakpoints.down(767)]: {
    height: '80px',
  },
  [theme.breakpoints.up(767)]: {
    height: '100px',
  },
}));

export const NavigationBar = () => {
  const appContext = useAppContext();
  const { url, componentOptions, assetUrl, headerLogoId } = appContext.useConfig();

  const extraHeaderImageRedirect = componentOptions?.component_navigation_bar_e_extra_logo_link;
  const extraHeaderImage = componentOptions?.component_navigation_bar_e_extra_image;

  return (
    <Container maxWidth={false}>
      <ToolbarContainer>
        <ButtonLogo component={Link} href="/" title={url} aria-label="Home">
          <Image
            loader={({ src }: ImageLoaderProps) => src}
            src={`${assetUrl}${headerLogoId}/`}
            unoptimized
            alt={url}
            layout="fill"
            objectFit="contain"
          />
        </ButtonLogo>
        {extraHeaderImage && (
          <ButtonLogo component={Link} href={extraHeaderImageRedirect} title={extraHeaderImage} aria-label="Home">
            <Image
              loader={({ src }: ImageLoaderProps) => src}
              src={extraHeaderImage}
              alt={url}
              layout="fill"
              objectFit="contain"
            />
          </ButtonLogo>
        )}
      </ToolbarContainer>
    </Container>
  );
};

const NavigationBarE = WithNavigationbar(NavigationBar);

export { NavigationBarE };