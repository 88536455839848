import React from "react";
import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import { Property } from 'csstype';
import { useEditorHandlers } from "./@editor";

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: theme.theme_component_navigation_bar_background_color || theme.palette.primary.main,
  backgroundImage: theme.theme_component_navigation_bar_c_background_image
    ? `url(https://${theme.theme_component_navigation_bar_c_background_image}/)`
    : theme.theme_component_navigation_bar_c_background_image_gradient
    ? theme.theme_component_navigation_bar_c_background_image_gradient
    : 'none',
  backgroundPosition: theme.theme_component_navigation_bar_background_position || 'center',
  backgroundSize: theme.theme_component_navigation_bar_background_size || 'cover',
  backgroundRepeat: theme.theme_component_navigation_bar_background_repeat || 'no-repeat',
  borderBottomWidth: theme.theme_component_navigation_bar_border_bottom_width
    ? `${theme.theme_component_navigation_bar_border_bottom_width}px`
    : 0,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.theme_component_navigation_bar_border_bottom_color || theme.palette.divider,
  color: theme.theme_component_navigation_bar_color || theme.palette.primary.contrastText,
  outlineOffset: '-2px', // Help out cursor context editor.
  position: theme.theme_component_navigation_position as Property.Position || 'sticky',
})) as typeof AppBar;

export const WithNavigationbar = (
  WrappedComponent: React.ComponentType
): React.FC => {
  const WithNavbar: React.FC = () => {
    return (
      <>
        <AppBarStyled {...useEditorHandlers('Navigation Bar')}>
          <WrappedComponent />
        </AppBarStyled>
      </>
    );
  };

  return WithNavbar;
};
